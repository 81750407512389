import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import items from '../../utils/item-fixtures';
import { formatCurrency } from "../../utils/japanese";

const PageTitle = styled.h1`
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 480px;
  margin: 0 auto 32px auto;
`;

const Button = styled.button`
  border: 0;
  background-color: #263238;
  color: #FFFFFF;
  font-weight: bold;
  height: 48px;
  max-width: 240px;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 12px;
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemImageWrapper = styled.div`
  width: 182px;
  height: 182px;
  border-radius: 16%;
  overflow: hidden;
  margin: 32px 0;
`

const ItemImage = styled.img`
  width: 100%;
  height: 100%;
`;

const ItemName = styled.span`
  margin-bottom: 8px;
  font-size: 14px;
`;

const ItemLabelValue = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
  font-size: 12px;
`;

const Separator = styled.hr`
  width: 100%;
  border: 0;
  border-top: 1px solid #EEF0F2;
  margin-top: 8px;
`;

const PayButton = styled(Button)`
  margin-top: 32px;
  font-size: 13px;
  font-weight: 600;
`;

const Details = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { id } = useParams();

  const selectedItem = items.find(item => item.id === id);

  const isDigital = selectedItem.digital && !selectedItem.subscription;
  const firstLabel = isDigital ? '枚数' : '数量';
  const secondLabel = isDigital ? '指定席' : '商品合計';

  return (
    <>
      <PageTitle>商品詳細</PageTitle>
      <Content>
        <ItemDetails>
          <ItemImageWrapper>
            <ItemImage src={selectedItem.imageSrc} />
          </ItemImageWrapper>
          <ItemName><b>{selectedItem.name}</b></ItemName>
          <ItemLabelValue>
            <span>{firstLabel}</span>
            <span>1</span>
          </ItemLabelValue>
          <ItemLabelValue>
            <span><b>{secondLabel}</b></span>
            <span>{formatCurrency(selectedItem.price)}</span>
          </ItemLabelValue>
          <Separator />
          <ItemLabelValue>
            <span><b>合計</b></span>
            <span><b>{formatCurrency(selectedItem.price)}</b></span>
          </ItemLabelValue>
        </ItemDetails>
        <PayButton onClick={() => {
          navigate(`/embedded/payment/${selectedItem.id}?${searchParams.toString()}`);
        }}>
          お支払い方法へ
        </PayButton>
      </Content>
    </>
  );
};

export default Details;
