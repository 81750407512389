import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const Header = styled.header`
  height: 48px;
  background-color: #FFFFFF;
  box-shadow: 0 0 8px 0 rgba(81, 37, 37, 0.25);
  z-index: 1;
  display: grid;
  grid-template-columns: 80px 1fr 80px;
  column-gap: 16px;
  padding: 0 16px;
  position: sticky;
  top: 0;
  
  & > div {
    display: flex;
    align-items: center;
    
    &:nth-child(2) {
      justify-content: center;
    }

    &:nth-child(3) {
      justify-content: right;
    }
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  
  & + & {
    margin-left: 16px;
  }
`

const PageTitle = styled.span`
  font-size: 13px;
  font-weight: 300;
`;

const MainWrapper = styled.main`
  background-color: #F8F8F9;
  flex: 1 0 0;
`;

const Main = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const Embedded = () => (
  <>
    <Header>
      <div>
        <Icon src="/assets/menu.png" />
      </div>
      <div>
        <PageTitle>SHOP</PageTitle>
      </div>
      <div>
        <Icon src="/assets/search.png" />
        <Icon src="/assets/cart.png" />
      </div>
    </Header>
    <MainWrapper>
      <Main>
        <Outlet />
      </Main>
    </MainWrapper>
  </>
);

export default Embedded;

