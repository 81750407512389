import React from 'react';
import styled from 'styled-components';

interface CustomOptionProps {
  option: {value: string; label: string};
  onSelect: (value: string, label: string) => void;
}

const StyledOption = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
  font-size: 14px;
  font-weight: 300;
`;

const CustomOption: React.FC<CustomOptionProps> = ({ option, onSelect }) => {
  return (
    <StyledOption onClick={() => onSelect(option.value, option.label)}>
      {option.label}
    </StyledOption>
  );
};

export default CustomOption;
