import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import items from '../../utils/item-fixtures';
import accounts from '../../utils/account-fixtures';
import { formatCurrency } from "../../utils/japanese";

const Wrapper = styled.div`
  max-width: 303px;
  min-width: 268px;
  width: 100%;
  margin: 0 auto;
`;

const PageTitle = styled.h1`
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 0;
`;

const Content = styled.div`
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
`;

const ItemImageContainer = styled.div`
  width: 80px;
  height: 80px;
`;

const ItemImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Button = styled.button`
  border: 0;
  background-color: #263238;
  color: #FFFFFF;
  font-weight: bold;
  height: 48px;
  width: 100%;
  max-width: 240px;
  border-radius: 4px;
  cursor: pointer;
`;

const ItemDetails = styled(Content)`
  flex-direction: row;
  margin: 16px auto 0 auto;
  justify-content: center;
`;

const ItemInfo = styled.div`
  margin-left: 24px;
`

const ItemName = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

const ItemPrice = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

const PaymentSection = styled.div`
  flex: 1 0 0;
  border-top: 1px solid #E1E1E1;
  margin: 16px -16px -16px -16px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;

`;

const PageSubtitle = styled.h4`
  text-align: center;
  margin: 16px 0;
  font-size: 12px;
`;

const PaymentMethod = styled(Content)`
  align-items: stretch;
  justify-content: flex-start;
`;

const RadioInputWrapper = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: 11px;
  font-weight: 400;
  
  & + & {
    margin-top: 16px;
  }
  
  & > span {
    flex: 1 0 0;
  }
  
  & input {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  
  & img {
    height: 24px;
  }
`;

const Banner = styled.div`
  margin-top: 16px;
  color: #4F4F4F;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  
  & img {
    width: 100%;
    margin-bottom: 16px;
  }
  
  & ul {
    margin: 0;
    padding-left: 16px;
  }

  & div {
    color: #8A8A8A;
  }
`;

const PayButton = styled(Button)`
  align-self: center;
  font-size: 13px;
  font-weight: 600;
  min-width: 121px;
  padding: 8px 12px;
  width: auto;
`;

const methods = [{
  label: 'あと払い（ペイディ）',
  value: 'paidy',
}, {
  label: 'クレジットカード',
  value: 'credit',
}, {
  label: '代金引換',
  value: 'cod',
}];

const Payment = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState('');

  const isDigital = searchParams.get('category').includes('digital') || false;
  const isInstallment = searchParams.get('installment') === 'true' || false;
  const isSubscription = searchParams.get('category').includes('subscription') || false;


  const selectedItem = items.find(item => {
    return item.id === id;
  });

  // const selectedAccount = accounts.find(account => {
  //   return account.subscription === isSubscription
  //     && account.digital === isDigital
  //     && account.installment === isInstallment
  //     && !account.firstTime;
  // });

  let paidyCheckoutObject = null; // Paidy.configure will be referenced here

  useEffect(() => {
    let script: HTMLScriptElement;

    if (paymentMethod === 'paidy') {
      script = document.createElement('script');
      script.src = 'https://apps.paidy-staging.com';
      script.addEventListener('load', setupPaidyCheckout);

      document.body.appendChild(script);
    }

    return () => {
      script?.removeEventListener('load', setupPaidyCheckout);
      script?.remove();

      (window as any).Paidy = null;
      paidyCheckoutObject = null;
      document.body.getElementsByTagName('iframe')[0]?.remove();
    }
  }, [paymentMethod]);

  const onClick = () => {
    if (paymentMethod !== 'paidy') {
      alert('「あと払い（ペイディ）」を選んでください。');
      return;
    }

    paidyCheckoutObject?.launch(generatePaidyCheckoutData());
  }

  const setupPaidyCheckout = () => {
    paidyCheckoutObject = (window as any).Paidy.configure({
      api_key: 'pk_test_ra321mmdf1qnlpuo5kkeihq78e',
      api_host: 'https://api.uat.paidy.io',
      app_host: 'https://checkout-v2.paidy-staging.com',
      on_close: (data) => {
        console.log({data});
        // 'authorized' for normal payments
        // 'active' for subscriptions
        if (data?.status === 'authorized' || data?.status === 'active') {
          navigate('/embedded/success');
        }
      },
      ...(isSubscription && {
        token: {
          wallet_id: 'default',
          type: 'recurring',
          description: ''
        },
      }),
    });
  };

  const generatePaidyCheckoutData = () => {
    return {
      is_demo: true,
      amount: selectedItem.price,
      description: selectedItem.name,
      store_name: 'Paidy Sample Shop',
      order: {
        tax: 0.0,
        shipping: 0.0,
        order_ref: 'test-order-ref',
        items: [{
          id: selectedItem.id,
          title: selectedItem.name,
          description: 'Test item description',
          unit_price: selectedItem.price,
          quantity: 1
        }]
      },
      currency: 'JPY',
      // buyer: {
      //   name1: '山田 太郎',
      //   name2: 'ヤマダ タロウ',
      //   dob: '1990-01-01',
      //   email: selectedAccount.email || '',
      //   phone: selectedAccount.phone || '',
      // },
      // ...(!isDigital && {
      //   shipping_address: {
      //     'line1': '9-7-1',
      //     'line2': '赤坂',
      //     'city': '港区',
      //     'state': '東京都',
      //     'zip': '1076212',
      //   }
      // }),
    };
  };

  return (
    <>
      <Wrapper>
        <PageTitle>購入商品</PageTitle>
        <ItemDetails>
          <ItemImageContainer>
            <ItemImage src={selectedItem.imageSrc}/>
          </ItemImageContainer>
          <ItemInfo>
            <ItemName>{selectedItem.name}</ItemName>
            <br/>
            <ItemPrice>{formatCurrency(selectedItem.price)}</ItemPrice>
          </ItemInfo>
        </ItemDetails>
        <PaymentSection>
          <PaymentMethod>
            <PageSubtitle>お支払い方法</PageSubtitle>
            {methods.map((method, index) => {
              // If Paidy is selected, hide the other options
              if (paymentMethod !== 'paidy' || (paymentMethod === 'paidy' && method.value === 'paidy')) {
                return (
                  <RadioInputWrapper key={index}>
                    <input
                      type="radio"
                      name="paymentMethod"
                      value={method.value}
                      checked={paymentMethod === method.value}
                      onChange={() => setPaymentMethod(method.value)}
                    />
                    <span>{method.label}</span>
                    {method.value === 'paidy' && <img src="/assets/paidy.png"/>}
                  </RadioInputWrapper>
                );
              }

              return null;
            })}
            {paymentMethod === 'paidy' && (
              <Banner>
                <img src="/assets/paidy-banner.png" />
                <ul>
                  <li>事前登録不要、クレジットカード登録不要。</li>
                  <li>メールアドレスと携帯電話番号だけで、今すぐ購入可能。</li>
                  <li>お支払いは翌月27日までに、コンビニ払い・銀行振込・口座振替で。</li>
                  <li>1か月に何度購入しても、お支払いは翌月まとめて1回でOK。</li>
                </ul>
                <p>さらに、ペイディアプリから本人確認をすると、分割手数料無料*の3回あ
  と払い**、ご利用可能額の確認、使い過ぎを防止する予算設定など、便利
  な機能がご利用いただけます。</p>
                <div>
                  <span>*口座振替・銀行振込のみ無料</span>
                  <br/>
                  <span>**1回のご利用金額が3,000円以上の場合のみ利用可能</span>
                </div>
              </Banner>
            )}
          </PaymentMethod>
          <PayButton onClick={onClick} style={{marginTop: paymentMethod === 'paidy' ? 24 : 91}}>
            {paymentMethod === 'paidy' ? 'あと払いペイディ画面に進む' : '次へ'}
          </PayButton>
        </PaymentSection>
      </Wrapper>
    </>
  );
};

export default Payment;
