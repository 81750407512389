import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { createGlobalStyle } from "styled-components";
import { normalize } from 'styled-normalize';

import Landing from './screens/Landing/Landing';
import Preview from './screens/Preview/Preview';
import Embedded from './screens/Embedded/Embedded';
import Selection from './screens/Embedded/Selection';
import Details from './screens/Embedded/Details';
import Payment from './screens/Embedded/Payment';
import Success from './screens/Embedded/Success';

const GlobalStyle = createGlobalStyle`
  ${normalize}
  
  * {
    box-sizing: border-box;
  }

  body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    text-rendering: optimizeLegibility;
    height: 100svh;
    color: #1C1C1C;
    font-size: 16px;
    font-family: 'Hiragino Sans', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif';
    font-weight: 300;
    line-height: 1.15;
  }
  
  b, select, button {
    font-weight: 600;
  }

  #root {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
  }
`;

const routes = createRoutesFromElements(
  <Route path="/">
    <Route index element={<Landing />} />
    <Route path="preview" element={<Preview />} />
    <Route path="embedded" element={<Embedded />}>
      <Route index element={<Selection />} />
      <Route path="item/:id" element={<Details />} />
      <Route path="payment/:id" element={<Payment />} />
      <Route path="success" element={<Success />} />
    </Route>
  </Route>
);

// React.StrictMode can cause the app to render twice
// This will only happen in development
createRoot(document.getElementById('root'))
  .render(
    <React.StrictMode>
      <GlobalStyle />
      <RouterProvider router={createBrowserRouter(routes)} />
    </React.StrictMode>
  );

