import * as React from 'react';
import styled from 'styled-components';

const Container = styled.footer`
  padding: 40px;
  background-color: #1C1C1C;
  background-image: url("/assets/footer-mobile-bg.jpg");
  background-repeat: repeat-x;
  color: #FFFFFF;
  
  @media only screen and (min-width: 1280px) {
    padding: 12px 0;
    background-image: url("/assets/footer-desktop-bg.jpg");
  }
`;

// There are two instances of CopyrightText, so we conditionally hide one by media query
const CopyrightText = styled.span`
  font-size: 10px;
  @media only screen and (min-width: 1280px) {
    display: none;
  }
`;

const Navigation = styled.nav`
  max-width: 1320px;
  padding: 0 40px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 1280px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Logo = styled.img`
  width: 104px;
  height: 40px;
  margin-bottom: 16px;

  @media only screen and (min-width: 1280px) {
    margin-bottom: 0;
  }
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

const ExternalLinks = styled.div`
  margin-bottom: 16px;
  
  & > * {
    vertical-align: text-bottom;
  }
  
  > a {
    display: inline-block;
    width: 16px;
    height: 16px;
    
    & + a {
      margin-left: 32px;  
    }
  }
  
  ${CopyrightText} {
    display: none;
  }
  
  @media only screen and (min-width: 1280px) {
    margin-bottom: 0;
    
    > a + a {
      margin-left: 32px;
    }

    ${CopyrightText} {
      display: initial;
      margin-left: 32px;
    }
  }
`;

const Footer = () => {
  return (
    <Container>
      <Navigation>
        <a href="https://paidy.com" target="_blank" rel="noreferrer noopener">
          <Logo src="/assets/paidy-grayscale.png" />
        </a>
        <ExternalLinks>
          <a href="https://twitter.com/PaidyJP" target="_blank" rel="noreferrer noopener">
            <Icon src="/assets/twitter.png" />
          </a>
          <a href="https://www.linkedin.com/company/paidy" target="_blank" rel="noreferrer noopener">
            <Icon src="/assets/linkedin.png" />
          </a>
          <CopyrightText>© Paidy Inc.</CopyrightText>
        </ExternalLinks>
        <CopyrightText>© Paidy Inc.</CopyrightText>
      </Navigation>
    </Container>
  );
};

export default Footer;
