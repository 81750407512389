// src/Dropdown.tsx
import React from 'react';
import styled, { css } from 'styled-components';
import CustomOption from './CustomOption';

interface DropdownProps {
  disabled?: boolean;
  isOpen: boolean;
  selectedCategory?: string;
  label: string;
  type: 'Category' | 'Settlement';
  options: {value: string; label: string}[];
  onChange: (value: string, label: string) => void;
  onClick: () => void;
}

const StyledDropdown = styled.div`
    position: relative;
    appearance: none;
    background-color: transparent;
    border: none;
    width: 100%;
    height: 56px;
    padding: 16px 56px;
    margin: 0;
    font-family: inherit;
    font-weight: 600;
    font-size: 14px;
    cursor: inherit;
    line-height: inherit;
    box-shadow: 0 4px 8px 0 rgba(81, 37, 37, 0.25);
    border-radius: 8px;
    display: grid;
    grid-template-areas: "select";

    &:not(:disabled) {
        color: inherit;
    }
`;

const Label = styled.div<{ $disabled?: boolean }>`
    display: flex;
    align-items: center;

    ${(props => props.$disabled && css`
      opacity: 0.6;
    `)}
`

const DropdownList = styled.div<{ $disabled?: boolean }>`
  overflow: hidden;
  top: 50px;
  position: absolute;
  z-index: 1000;
  background-color: #FAFAFA;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
`;

const Dropdown: React.FC<DropdownProps> = ({ disabled, isOpen, type, options, selectedCategory, label, onClick, onChange }) => {
  const handleLabelClick = () => {
    if(type === 'Settlement' && ['カテゴリーを選ぶ','定期/サブスク（物販）','定期/サブスク（物販以外）'].includes(selectedCategory)) return;
    onClick();
  }

  const handleSelect = (value: string, label: string) => {
    onChange(value, label);
    onClick();
  }
  return (
    <StyledDropdown >
      <Label onClick={handleLabelClick} $disabled={disabled} >{label}</Label>
      {isOpen && (
        <DropdownList>
          {options.map((option, index) => (
            <CustomOption key={index} option={option} onSelect={handleSelect} />
          ))}
        </DropdownList>
      )}
    </StyledDropdown>
  );
};

export default Dropdown;
