import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Content = styled.div`
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
`;

const Wrapper = styled(Content)`
  flex: 0;
  margin-top: 64px;
`;

const HeadingText = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
`;

const Message = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-top: 18px;
  margin-bottom: 0;
`;

const Button = styled.button`
  border: 0;
  background-color: #263238;
  color: #FFFFFF;
  font-weight: bold;
  height: 48px;
  max-width: 240px;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 12px;
`;

const BackToTopButton = styled(Button)`
  margin-top: 57px;
  flex-shrink: 0;
`;

const Success = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <HeadingText>Thank you!</HeadingText>
      <Message>ご注文ありがとうございました。</Message>
      <Message>ご注文番号：123456789</Message>
      <BackToTopButton onClick={() => { navigate(-3) }}>トップへ戻る</BackToTopButton>
    </Wrapper>
  );
}

export default Success;
