import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 16px;
  position: relative;

  @media only screen and (min-width: 1280px) {
    margin: 0;
  }
`;

const HeroImage = styled.img`
  width: 100%;
  max-width: 480px;
  // By setting the opacity, the images will still be loaded by the browser even when not visible
  opacity: 0; 
  transition: opacity 0.8s ease-in;

  &.active {
    opacity: 1;
  }

  // Make the images bigger on big screens
  @media only screen and (min-width: 1280px) {
    transform: scale(1.5);
  }
  
  &:not(:first-child) {
    position: absolute; // The first image sets the size of the container
    left: 0;
    top: 0;
  }
`;

// Shuffle through the Checkout screens to show the flow
const Hero = () => {
  const container = useRef<HTMLDivElement>(null);
  const [hasAllImagesLoaded, setHasAllImagesLoaded] = useState(false);

  // Ensures all images have loaded first before starting the slideshow
  useEffect(() => {
    const imgEls = container.current.getElementsByTagName('img');

    const loaded = Array.from(imgEls).map((imgEl, index) => {
      return new Promise((resolve) => {
        const resolveValue = () => {
          resolve(imgEl);
          // Always cleanup
          imgEl.removeEventListener('load', resolveValue);
        };

        imgEl.addEventListener('load', resolveValue);

        // Due to a Webkit quirk, the `load` listener does not work when `src` value is put directly in the markup.
        // So we defer it by setting the value here.
        imgEl.src = `/assets/hero-image-${index}.png`;
      });
    });

    Promise.all(loaded).then(() => {
      setHasAllImagesLoaded(true);
      imgEls[0].classList.add('active');
    });
  }, []);

  // Start the slideshow only when all images have loaded
  useEffect(() => {
    if (hasAllImagesLoaded) {
      const slides = container.current.getElementsByTagName('img');
      let currentSlideIndex = 0;

      const updateSlide = setInterval(() => {
        const nextSlideIndex = (currentSlideIndex + 1) % slides.length;
        slides[currentSlideIndex].classList.remove('active');
        slides[nextSlideIndex].classList.add('active');
        currentSlideIndex = nextSlideIndex;
      }, 2000);

      return () => clearInterval(updateSlide);
    }
  }, [hasAllImagesLoaded]);

  return (
    <Container ref={container}>
        {/*First image will be visible by default*/}
        <HeroImage />
        <HeroImage />
        <HeroImage />
        <HeroImage />
        <HeroImage />
        <HeroImage />
        <HeroImage />
    </Container>
  );
};

export default Hero;
