import React, { useEffect, useMemo, useState } from 'react';
import { Link, ScrollRestoration, useLocation, useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Footer from '../../components/Footer/Footer';
import Dropdown from '../../components/DropDown/DropDown';

// Subject to change over time.
const MOBILE_MAX_WIDTH = 360;
const MOBILE_MAX_HEIGHT = 640;
const DESKTOP_MAX_WIDTH = 1280;
const DESKTOP_MAX_HEIGHT = 768;

// Simulate the device bezels
const DEVICE_BEZEL_THICKNESS = 8;
const MOBILE_BEZEL_RADIUS = 32;
const DESKTOP_BEZEL_RADIUS = 16;

const CATEGORIES = [{
  value: '',
  label: 'カテゴリーを選ぶ',
},{
  value: 'tangible',
  label: '物販',
}, {
  value: 'digital',
  label: '物販以外',
}, {
  value: 'tangible-subscription',
  label: '定期/サブスク（物販）',
}, {
  value: 'digital-subscription',
  label: '定期/サブスク（物販以外）',
}];

const INSTALLMENTS = [{
  value: '',
  label: '支払い回数を選ぶ',
},{
  value: 'false',
  label: '一括払いのみ',
}, {
  value: 'true',
  label: '一括払い/3・6・12回あと払い（本人確認済）',
}];

const Header = styled.header`
  padding: 40px 0;
  background-color: #FFFFFF;
`;

const Logo = styled.img`
  width: 120px;
  height: 48px;
`;

const Navigation = styled.nav`
  padding: 0 16px;
  display: grid;
  
  a {
    display: inline-block;
  }
  
  // Div that contains the logo for the desktop view
  & > div:first-child {
    display: none;
  }

  @media only screen and (min-width: 1280px) {
    max-width: 1320px;
    padding: 0 40px;
    margin: auto;
    grid-template-columns: 120px 1fr 120px;
    column-gap: 80px;
    align-items: start;

    // Div that contains the logo for the desktop view
    & > div:first-child {
      display: initial;
    }
  }
`;

const ViewportSizeOption = styled.label`
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  
  input {
    position: absolute;
    appearance: none;
  }
  
  input, img {
    width: 100%;
    height: 100%;
  }
  
  input:not(:checked) + img {
    opacity: .5;
  }
  
  & + & {
    margin-left: 8px;
  }
`;

const NavigationFirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: center;

  & > div:first-child span {
    display: none;
  }

  @media only screen and (min-width: 1280px) {
    & > div:first-child {
      span {
        display: initial;
      }
      img {
        display: none;  
      }
    }
  }
`;

// Not displayed on desktop view
const NavigationSecondRow = styled.div`
  margin-bottom: 24px;

  @media only screen and (min-width: 1280px) {
    display: none;
  }
`;

const NavigationThirdRow = styled.div`
  display: grid;
  row-gap: 16px;

  @media only screen and (min-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
  }
`;

const SelectWrapper = styled.div<{ $disabled?: boolean }>`
  position: relative;

  &::before, &::after {
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    ${(props => props.$disabled && css`
      opacity: 0.6;
    `)}
  }
  
  &::before {
    left: 24px;
  }

  &::after {
    right: 24px;
    background-image: url("/assets/icon-chevron-down.png");
  }
`;

const Select = styled.select`
  appearance: none;
  background-color: transparent;
  border: none;
  width: 100%;
  height: 56px;
  padding: 16px 56px;
  margin: 0;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  cursor: inherit;
  line-height: inherit;
  box-shadow: 0 4px 8px 0 rgba(81, 37, 37, 0.25);
  border-radius: 8px;
  display: grid;
  grid-template-areas: "select";
  
  &:not(:disabled) {
    color: inherit;
  }
`;

const CategorySelectWrapper = styled(SelectWrapper)`
  &::before {
    background-image: url("/assets/icon-category.png");
  }
`;

const InstallmentSelectWrapper = styled(SelectWrapper)`
  &::before {
    background-image: url("/assets/icon-installment.png");
  }
`;

const Main = styled.main`
  flex: 1 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: linear-gradient(204deg, #F4F1EB 0%, #DBD5D1 100%);
  padding: 40px 16px;

  @media only screen and (min-width: 1280px) {
    padding: 40px;
  }
`;

const DeviceScreen = styled.div<{ $size: string }>`
  box-shadow: 0 8px 20px 0 rgba(81, 37, 37, 0.25);
  background-color: #FFFFFF;
  display: flex;
  
  ${(props) =>
    props.$size === 'desktop'
      ? css`
        // * 2 because bezels are on both sides of the screen
        width: ${DESKTOP_MAX_WIDTH + (DEVICE_BEZEL_THICKNESS * 2)}px;
        height: ${DESKTOP_MAX_HEIGHT + (DEVICE_BEZEL_THICKNESS * 2)}px;
        padding: ${DEVICE_BEZEL_THICKNESS}px;
        border-radius: ${DESKTOP_BEZEL_RADIUS}px;
        > * {
          // Create nested rounded borders beautifully
          border-radius: ${DESKTOP_BEZEL_RADIUS - DEVICE_BEZEL_THICKNESS}px;
          overflow: hidden;
        }
      `
      : css`
        // * 2 because bezels are on both sides of the screen
        width: 318px;
        height: ${MOBILE_MAX_HEIGHT + (DEVICE_BEZEL_THICKNESS * 2)}px;
        padding: ${DEVICE_BEZEL_THICKNESS}px;
        border-radius: ${MOBILE_BEZEL_RADIUS}px;
        > * {
          // Create nested rounded borders beautifully
          border-radius: ${MOBILE_BEZEL_RADIUS - DEVICE_BEZEL_THICKNESS}px;
          overflow: hidden;
        }
      `
  };
`;

const IFrame = styled.iframe`
  border: 1px solid #F0F0F0;
  width: 100%;
`;

const Placeholder = styled.div`
  background-color: #FAFAFA;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #F0F0F0;
`;

const Preview = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [size, setSize] = useState('desktop');
  const [category, setCategory] = useState('');
  const [installment, setInstallment] = useState('');
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isInstallmentOpen, setIsInstallmentOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('カテゴリーを選ぶ');
  const [selectedSettlement, setSelectedSettlement] = useState('支払い回数を選ぶ');


  const isSubscription = useMemo(() => category.includes('subscription'), [category]);

  useEffect(() => {
    setSize(searchParams.get('size') || 'desktop');
    setCategory(searchParams.get('category') || '');
    setInstallment(searchParams.get('installment') || '');
  }, [location]);

  const updateSearchParam = (param: { [name: string]: string }) => {
    const paramsObj = { size, category, installment, ...param };

    // Reset the installment when a new category is selected
    if (paramsObj.category !== category) {
      paramsObj.installment = '';
    }

    // Strip off props with undefined or empty string values to make the URL look clean
    Object.keys(paramsObj).forEach((key) => {
      !paramsObj[key] && delete paramsObj[key];
    });

    setSearchParams(paramsObj);
  };

  const ScreenContent = () => {
    const screenOn = <IFrame src={`/embedded?category=${category}&installment=${installment}`} />;
    const screenOff = (
      <Placeholder>
        <Logo src="/assets/paidy.png" />
      </Placeholder>
    );

    if (category) {
      if ((!isSubscription && installment) || (isSubscription && !installment)) {
        return screenOn;
      }
    }

    return screenOff;
  };

  return (
    <>
      {/* ScrollRestoration only needs to be rendered on a single component in the entire app */}
      <ScrollRestoration />
      <Header>
        <Navigation>
          <div>
            <Link to="/">
              <Logo src="/assets/paidy.png" />
            </Link>
          </div>
          <div>
            <NavigationFirstRow>
              <div>
                <Link to="/">
                  <Logo src="/assets/paidy.png" />
                </Link>
                <span>
                  <b>あと払いペイディ</b> | デモ
                </span>
              </div>
              <div>
                <ViewportSizeOption>
                  <input
                    type="radio"
                    name="viewport"
                    value="desktop"
                    checked={size === 'desktop'}
                    onChange={() => updateSearchParam({ size: 'desktop' })} />
                  <img src="/assets/desktop.png" alt="Desktop screen preview" />
                </ViewportSizeOption>
                <ViewportSizeOption>
                  <input
                    type="radio"
                    name="size"
                    value="mobile"
                    checked={size === 'mobile'}
                    onChange={() => updateSearchParam({ size: 'mobile' })} />
                  <img src="/assets/mobile.png" alt="Mobile screen preview" />
                </ViewportSizeOption>
              </div>
            </NavigationFirstRow>
            <NavigationSecondRow>
              <b>あと払いペイディ</b> | デモ
            </NavigationSecondRow>
            <NavigationThirdRow>
              <CategorySelectWrapper>
                <Dropdown type='Category' options={CATEGORIES.slice(1)} isOpen={isCategoryOpen} label={selectedCategory} onClick={() => {
                  setIsCategoryOpen(!isCategoryOpen);
                  if(isInstallmentOpen) setIsInstallmentOpen(!isInstallmentOpen)
                }} onChange={(value, label) => {
                  updateSearchParam({ category: value })
                  setSelectedCategory(label);
                  setSelectedSettlement('支払い回数を選ぶ')
                }} />
              </CategorySelectWrapper>
              <InstallmentSelectWrapper $disabled={!category || isSubscription}>
                <Dropdown type='Settlement' onClick={() => {
                  setIsInstallmentOpen(!isInstallmentOpen)
                  if(isCategoryOpen) setIsCategoryOpen(!isCategoryOpen);
                }} disabled={!category || isSubscription} isOpen={isInstallmentOpen} selectedCategory={selectedCategory} options={INSTALLMENTS.slice(1)} label={selectedSettlement} onChange={(value,label) => {
                  updateSearchParam({ installment: value })
                  setSelectedSettlement(label);
                }} />
              </InstallmentSelectWrapper>
            </NavigationThirdRow>
          </div>
        </Navigation>
      </Header>
      <Main>
        <DeviceScreen $size={size}>
          <ScreenContent />
        </DeviceScreen>
      </Main>
      <Footer />
    </>
  );
};

export default Preview;
