import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Hero from '../../components/Hero/Hero';
import Footer from '../../components/Footer/Footer';

const Main = styled.main`
  flex: 1 0 0;
  background: url("/assets/landing-bg.jpg") #fff no-repeat center;
  background-size: cover;
  padding: 24px;

  display: grid;
  align-items: center;
  justify-items: center;

  @media only screen and (max-width: 375px) {
    background: url("/assets/landing-small-375.png") #F4F1EB no-repeat;
  }
  @media only screen and (min-width: 376px) and (max-width: 563px) {
    background: url("/assets/landing-small-563.png") #F4F1EB no-repeat right;
  }
  @media only screen and (min-width: 564px) and (max-width: 750px) {
    background: url("/assets/landing-small-750.png") #F4F1EB no-repeat right;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  & > div:nth-child(1) {
    display: none;
  }

  @media only screen and (min-width: 1280px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "logo logo"
      "slides card";
    align-items: center;
    justify-items: center;
    row-gap: 100px;
    margin-bottom: 126px;

    & > div:nth-child(1) {
      grid-area: logo;
      display: initial;
    }

    & > div:nth-child(2) {
      grid-area: slides;
    }

    & > div:nth-child(3) {
      grid-area: card;
    }
  }
`;

const Logo = styled.img`
  width: 104px;
  height: 40px;
`;

const Card = styled.div`
  background: #FAFAFA;
  padding: 32px 16px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 680px;
  z-index: 1;
  
  & ${Logo} {
    margin-bottom: 24px;
    @media only screen and (min-width: 1280px) {
      display: none;
    }
  }

  @media only screen and (min-width: 768px) {
    padding: 32px;
  }
`;

const CardContentWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const CardTitleText = styled.span`
  font-weight: 600;

  @media only screen and (min-width: 1280px) {
    font-size: 20px;
  }
`;

const CardHighlightText = styled.span`
  background: #1C1C1C;
  padding: 8px 16px;
  font-size: 26px;
  font-weight: 600;
  color: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 24px;
  text-align: center;

  @media only screen and (min-width: 1280px) {
    font-size: 36px;
  }
`;

const CardUL = styled.ul`
  font-weight: 300;
  margin: 0;
  padding-left: 24px;
  text-align: left;
  
  & > li {
    line-height: 1.5;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

const SemiBoldText = styled.span`
  font-weight: 600;
`;

const TileWrapper = styled(CardContentWrapper)`
  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const Tile = styled.div`
  background-color: #FFFFFF;
  padding: 16px;
  border-radius: 18px;
  box-shadow: 0 4px 4px 0 rgba(81, 37, 37, 0.10);
  width: 100%;
  min-height: 100px;
  font-weight: 500;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  
  @media only screen and (min-width: 768px) {
    margin-bottom: 0;
    max-width: 180px;
    min-height: 140px;

    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
`;

const TileTitleText = styled.span`
  margin-bottom: 8px;
  text-align: center;
`;

const TileBannerText = styled.b`
  text-align: center;
  color: #E30E80;
  font-size: 32px;
  font-weight: 700;
  word-break: keep-all; // Works in tandem with <wbr> so Japanese words break in appropriate places
`;

const TileCaptionText = styled.small`
  margin-top: 8px;
  font-size: 10px;
  font-weight: 300;
  color: #4F4F4F;
`;

const Button = styled.div`
  background: linear-gradient(135deg, #E5258C 0%, #7D35D4 100%);
  color: #FFFFFF;
  line-height: 1.5;
  text-align: center;
  padding: 16px;
  height: 56px;
  border-radius: 28px;
  width: 100%;
  cursor: pointer;
  font-weight: 600;

  @media only screen and (min-width: 768px) {
    max-width: 328px;
    align-self: center;
  }
`;

const CardCaptionText = styled.small`
  color: #4F4F4F;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.5;

  @media only screen and (min-width: 768px) {
    align-self: flex-start;
  }
`;

const Landing = () => {
  const navigate= useNavigate();

  return (
    <>
      <Main>
        <ContentWrapper>
          <div>
            <Logo src="/assets/paidy.png" />
          </div>
          <Hero />
          <Card>
            <Logo src="/assets/paidy.png" />
            <CardContentWrapper>
              <CardTitleText>売上アップと業務効率化を実現</CardTitleText>
            </CardContentWrapper>
            <CardContentWrapper>
              <CardHighlightText>あと払いペイディ</CardHighlightText>
            </CardContentWrapper>
            <CardContentWrapper>
              <CardUL>
                <li>
                  <span>
                    クレジットカード不要、事前登録不要で、<SemiBoldText>カゴ落ちを防止</SemiBoldText>して売上アップに貢献。
                  </span>
                </li>
                <li>
                  <span>
                    分割手数料無料*の3・6・12回あと払いで、<SemiBoldText>高額商品の売上アップ</SemiBoldText>が見込める。
                  </span>
                </li>
                <li>
                  <span>
                    余計な事務作業が不要で、<SemiBoldText>業務効率化を実現</SemiBoldText>。
                  </span>
                </li>
              </CardUL>
            </CardContentWrapper>
            <TileWrapper>
              <Tile>
                <TileBannerText>業界<wbr />No.1*</TileBannerText>
                <TileCaptionText>*後払い決済導入社数において。当社調べ。</TileCaptionText>
              </Tile>
              <Tile>
                <TileTitleText>ペイディ加盟店</TileTitleText>
                <TileBannerText>70万店舗<wbr />以上</TileBannerText>
              </Tile>
              <Tile>
                <TileTitleText>利用者数</TileTitleText>
                <TileBannerText>急速に<wbr />増加中</TileBannerText>
              </Tile>
            </TileWrapper>
            <CardContentWrapper>
              <Button onClick={() => navigate('/preview')}>あと払いペイディ画面に進む</Button>
            </CardContentWrapper>
            <CardCaptionText>*口座振替・銀行振込のみ無料</CardCaptionText>
          </Card>
        </ContentWrapper>
      </Main>
      <Footer />
    </>
  );
};

export default Landing;
