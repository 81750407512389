// Filter accounts to be used by the following:
// digital: can be used to pay for non-tangible (digital) goods
// installment: simulate installments using this account
// subscription: simulate subscriptions (Paidy token) using this account
// requireConsumerData: simulate requiring consumer data (Plus flow) using this account
// rejected: simulate rejected payments using this account
// merchant id -  Smith - Rogahn (mer_Zx8pPmAAAD0APuSr) - pk_test_1bq7j563o0oaanhvtme787i28d

const accounts = [
  // 物販 (Base)
  {
    email: 'successful.classic.payment@paidy.com',
    phone: '08000000001',
    merchant: 'pk_test_1bq7j563o0oaanhvtme787i28d', 
    digital: false,
    installment: false,
    subscription: false,
    requireConsumerData: false,
    rejected: false
  },
  {
    email: 'successful.payment@paidy.com',
    phone: '08000000001',
    merchant: 'pk_test_1bq7j563o0oaanhvtme787i28d',
    digital: false,
    installment: true,
    subscription: false,
    requireConsumerData: false,
    rejected: false
  },
  // 物販以外 (digital)
  {
    email: 'successful.first.payment@paidy.com',
    phone: '08000000001',
    // use digital merchant
    merchant: 'pk_test_ra321mmdf1qnlpuo5kkeihq78e',
    digital: true,
    installment: true,
    subscription: false,
    requireConsumerData: false,
    rejected: false
  },
  {
    email: 'successful.classic.payment@paidy.com',
    phone: '08000000001',
    // use digital merchant
    merchant: 'pk_test_ra321mmdf1qnlpuo5kkeihq78e',
    digital: true,
    installment: false,
    subscription: false,
    requireConsumerData: false,
    rejected: false
  },
  // 定期/サブスク（物販）
  {
    email: 'successful.payment@paidy.com',
    phone: '08000000001',
    merchant: 'pk_test_1bq7j563o0oaanhvtme787i28d',
    digital: false,
    installment: false,
    subscription: true,
    requireConsumerData: false,
    rejected: false
  },
  // 定期/サブスク（物販以外）
  {
    email: 'successful.first.payment@paidy.com',
    phone: '08000000001',
    // use digital merchant
    merchant: 'pk_test_ra321mmdf1qnlpuo5kkeihq78e',
    digital: true,
    installment: false,
    subscription: true,
    requireConsumerData: false,
    rejected: false
  },
];

export default accounts;
