import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import items from '../../utils/item-fixtures';
import { formatCurrency } from "../../utils/japanese";

const PageTitle = styled.h1`
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 0;
`;

const Content = styled.div`
  margin-top: 32px;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SelectionGrid = styled.div<{ $size: number }>`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 56px;
  
  ${(props) =>
    props.$size === 1 ? '' : css`
      grid-template-columns: repeat(2, 1fr);
    `
  }
`;

const ItemContainer = styled(Link)`
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
`;

const ItemImageWrapper = styled.div`
  width: 113px;
  height: 113px;
  max-width: 176px;
  border-radius: 16%;
  overflow: hidden;
  margin-bottom: 12px;
`;

const ItemImage = styled.img`
  width: 100%;
  height: 100%;
`;

const ItemName = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

const ItemPrice = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

const Selection = () => {
  const [searchParams] = useSearchParams();

  // Show some items only by selected category
  const filteredItems = items.filter(item => {
    const category = searchParams.get('category');
    return item.digital === category.includes('digital')
      && item.subscription === category.includes('subscription');
  });

  const Item = (props: { id: string; imageSrc: string; name: string; price: number; monthOrYear: undefined | string; }) => {
    return (
      <ItemContainer to={`/embedded/item/${props.id}?${searchParams.toString()}`}>
        <ItemImageWrapper>
          <ItemImage src={props.imageSrc} />
        </ItemImageWrapper>
        <ItemName>{props.name}</ItemName>
        <br />
        <ItemPrice>{formatCurrency(props.price)}{props.monthOrYear && `/${props.monthOrYear}`}</ItemPrice>
      </ItemContainer>
    );
  };

  return (
    <>
      <PageTitle>商品一覧</PageTitle>
      <Content>
        <SelectionGrid $size={filteredItems.length}>
          {filteredItems.map(item => (
            <Item key={item.id} id={item.id} imageSrc={item.imageSrc} name={item.name} price={item.price} monthOrYear={item.monthOrYear} />)
          )}
        </SelectionGrid>
      </Content>
    </>
  );
};

export default Selection;
