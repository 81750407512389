const items = [{
  id: 'p-001-nd', // product-001-non-digital
  name: 'スニーカー',
  price: 12000,
  imageSrc: '/assets/item-sneaker.png',
  digital: false,
  subscription: false,
  monthOrYear: undefined,
}, {
  id: 'p-002-nd',
  name: 'パーカー',
  price: 15000,
  imageSrc: '/assets/item-parker.png',
  digital: false,
  subscription: false,
  monthOrYear: undefined,
}, {
  id: 'p-003-nd',
  name: 'バッグ',
  price: 7500,
  imageSrc: '/assets/item-bag.png',
  digital: false,
  subscription: false,
  monthOrYear: undefined,
}, {
  id: 'p-004-nd',
  name: 'スカート',
  price: 5500,
  imageSrc: '/assets/item-skirt.png',
  digital: false,
  subscription: false,
  monthOrYear: undefined,
}, {
  id: 'p-001-d', // product-001-digital
  name: 'ライブ・コンサート',
  price: 12000,
  imageSrc: '/assets/item-live-concert.png',
  digital: true,
  subscription: false,
  monthOrYear: undefined,
}, {
  id: 'p-002-d',
  name: 'スポーツ',
  price: 7000,
  imageSrc: '/assets/item-sports.png',
  digital: true,
  subscription: false,
  monthOrYear: undefined,
}, {
  id: 'p-003-d',
  name: 'イベント',
  price: 19000,
  imageSrc: '/assets/item-event.png',
  digital: true,
  subscription: false,
  monthOrYear: undefined,
}, {
  id: 'p-004-d',
  name: 'アート',
  price: 3000,
  imageSrc: '/assets/item-art.png',
  digital: true,
  subscription: false,
  monthOrYear: undefined,
}, {
  id: 'p-001-d-s', // product-001-digital-subscription
  name: '月額プラン',
  price: 980,
  imageSrc: '/assets/item-streaming.png',
  digital: true,
  subscription: true,
  monthOrYear: '月',
}, {
  id: 'p-002-d-s', // product-002-digital-subscription
  name: '年額プラン',
  price: 9800,
  imageSrc: '/assets/item-streaming.png',
  digital: true,
  subscription: true,
  monthOrYear: '年'
},{
  id: 'p-001-nd-s', // product-001-digital-subscription
  name: '月額プラン',
  price: 1200,
  imageSrc: '/assets/item-music.png',
  digital: false,
  subscription: true,
  monthOrYear: '月',
},{
  id: 'p-002-nd-s', // product-002-digital-subscription
  name: '年額プラン',
  price: 12000,
  imageSrc: '/assets/item-music.png',
  digital: false,
  subscription: true,
  monthOrYear: '年'
}];

export default items;
